import React from 'react';
import { Box, Typography } from '@mui/material';
import { PropertyByTownship } from '../../data/homePageData';

const UserpropertyByTownshipCard = ({history}) => {
  const handleDetail =(data) => {
    console.log('label', data)
    history.push(`/property?State=All&location=${data}`)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginY: '30px' }}>
      <Typography
        variant='p'
        className='UserPageTitleStyle'
        sx={{
          paddingLeft: { xs: '20px', md: '95px' },
          marginBottom: '20px',
          textAlign: { xs: 'center', md: 'left' }
        }}
      >
        ပြည်နယ်/တိုင်းဒေသကြီးအလိုက် အိမ်ခြံမြေ
      </Typography>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: { xs: '10px', md: '80px' }, // Reduced gap for small screens
        justifyContent: { xs: 'flex-start', md: 'flex-start' },
        overflowX: 'auto', // Enables horizontal scrolling
        padding: { xs: '0 20px', md: '0 95px' },
        scrollSnapType: 'x mandatory', 
      }}>
        {PropertyByTownship.map((township, index) => (
          <Box
            key={index}
            onClick={() => handleDetail(township.data)}
            sx={{
              minWidth: '200px', // Use minWidth instead of width for responsiveness
              height: '250px',
              backgroundImage: `url(${township.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              scrollSnapAlign: 'center',
              '&:hover': {
                  transform: 'scale(1.01)', // Scale the Box on hover to create a pop effect
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Add a shadow to enhance the effect
                } // Snap to the center of the card
            }}
          >
            <Typography
              variant="p"
              sx={{
                color: 'white',
                textShadow: '4px 2px 3px #474747',
                fontWeight: '600'
              }}
            >
              {township.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UserpropertyByTownshipCard;
