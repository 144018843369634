import axios from "axios";
import { _DecryptService } from "../../service/EncryptDecryptService";
import { toast } from "react-toastify"; // Ensure you have this package installed

const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT; // Base URL for your API


const GetAdsPageAPI = async (setAdsPagePlacements) => {
  try {
    const endpoint = `${API_BASE_URL}feature-ads-page`;
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json"
    };

    const res = await axios.get(endpoint, { headers });

    if (res.data.StatusCode === 200) {
      console.log("Ad retrieved successfully"); // Updated log message
      toast.success("Ad retrieved successfully.");
      console.log(res.data.Data.DataLst); // Set state with the retrieved data
      setAdsPagePlacements(res.data.Data.DataLst);
    } else {
      toast.error(res.data.Message || "Failed to retrieve the ad.");
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
};

/**
 * Retrieves ads with pagination.
 * @param {number} pageNo - The page number for pagination.
 * @param {number} pageSize - The number of items per page.
 * @param {Function} setData - Callback to set the retrieved data.
 * @param {Function} toast - Toastify instance for showing notifications.
 * @param {Function} setTotalCount - Callback to set the total count of records.
 * * @param {Function} setIsLoading - Callback to set the total count of records.
 */

const GetAdsAPI = async (payload, setData, toast, setTotalCount, setIsLoading) => {
  try {
    const endpoint = `${API_BASE_URL}feature-ads/filter`;
    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json"
    };

    const res = await axios.post(endpoint, payload, { headers: headers });

    if (res.data.StatusCode === 200) {
      setData(res?.data?.Data?.DataLst || []);
      const totalRecords = res?.data?.Data?.PageSetting?.TotalCount || 0;
      setTotalCount(totalRecords);
    } else if (res.data.StatusCode === 401) {
      alert(res.data.Message);
      window.location.replace('/admin/login');
    } 
  } catch (err) {
    console.error("Error", err);
  } finally {
    setIsLoading(false); // Set loading to false after the API call is complete
  }
};


// const GetAdsAPI = async (UserId, pageNo, pageSize, setData, toast, setTotalCount, setIsLoading) => {
//   try {
//     const endpoint = `${API_BASE_URL}feature-ads?userId=${UserId}&pageNo=${pageNo}&pageSize=${pageSize}`;
//     console.log("Endpoint", endpoint);

//     const headers = {
//       "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
//       "Content-Type": "application/json"
//     };

//     const res = await axios.get(endpoint, { headers: headers });

//     if (res.data.StatusCode === 200) {
//       console.log("Ads data retrieved successfully");
//       console.log('From api',res.data.Data.DataLst);
//       setData(res?.data?.Data?.DataLst || []);
//       const totalRecords = res?.data?.Data?.PageSetting?.TotalCount || 0;
//       setTotalCount(totalRecords);
//       setIsLoading(false);
//       console.log("Total Count:", totalRecords);
//     } else if (res.data.StatusCode === 401) {
//       alert(res.data.Message);
//       window.location.replace('/admin/login');
//     } else {
//       toast.error(res.data.Message);
//       setIsLoading(false);
//     }
//   } catch (err) {
//     console.error("Error", err);
//     setIsLoading(false);
//   }
// };


/**
 * Deletes an ad by its ID.
 * @param {string} adId - The ID of the ad to delete.
 * @param {Function} onSuccess - Callback function to call on successful deletion.
 */
const DeleteAdsAPI = async (UserId,adId, toast, setData,  setTotalCount, setIsLoading) => {
  try {
    const endpoint = `${API_BASE_URL}feature-ads?id=${adId}`;
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json",
    };

    const res = await axios.delete(endpoint, { headers: headers });

    if (res.data.StatusCode === 200) {
      console.log("Ads deleted successfully");
      toast.success("Ads deleted successfully!");     
    } else if (res.data.StatusCode === 401) {
      alert(res.data.Message);
      window.location.replace('/admin/login');
    } else {
      toast.error(res.data.Message);
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
};

// const CreateAdsAPI = async (adsData) => {
//   try {
//     const endpoint = `${API_BASE_URL}admin-ads`;
//     console.log("Endpoint", endpoint);

//     const headers = {
//       "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
//     };

//     // const formData = new FormData();
//     // for (const key in adsData) {
//     //   if (adsData[key]) {
//     //     if (Array.isArray(adsData[key])) {
//     //       adsData[key].forEach((item) => formData.append(key, item));
//     //     } else {
//     //       formData.append(key, adsData[key]);
//     //     }
//     //   }
//     // }

//     const formData = new FormData();

//     // Manually append each known key-value pair
//     if (adsData.Title) formData.append('Title', adsData.Title);
//     if (adsData.TargetUrl) formData.append('TargetUrl', adsData.TargetUrl);
//     if (adsData.StartDate) formData.append('StartDate', adsData.StartDate);
//     if (adsData.EndDate) formData.append('EndDate', adsData.EndDate);
//     if (adsData.CreatedBy) formData.append('CreatedBy', adsData.CreatedBy);

//     if (adsData.AdsPagePlacements) formData.append('AdsPagePlacements', adsData.AdsPagePlacements);


//     if (adsData.Files) formData.append('Files', adsData.Files);


//     if (adsData.AdsLayout) formData.append('AdsLayout', adsData.AdsLayout);


//     const res = await axios.post(endpoint, formData, { headers: headers });

//     if (res.data.StatusCode === 200) {
//       console.log("Ad created successfully");
//       toast.success("Ad created successfully.");
//     } else {
//       toast.error(res.data.Message || "Failed to create the ad.");
//     }
//   } catch (err) {
//     console.error("Error", err);
//     toast.error("Unknown Error");
//   }
// };

// // Append each known key-value pair
// formData.append('Title', adsData.Title);
// formData.append('TargetUrl', adsData.TargetUrl);
// formData.append('StartDate', adsData.StartDate);
// formData.append('EndDate', adsData.EndDate);
// formData.append('CreatedBy', adsData.CreatedBy);


// adsData.AdsPagePlacements.forEach((placement, index) => {

//   formData.append(`AdsPagePlacements[${index}].AdsPageId`, placement.AdsPageId); // Format for API
// })




// adsData.Files.forEach((file) => {
//   formData.append('Files', file); // Append each file
// });


// formData.append('AdsLayout', adsData.AdsLayout);

const CreateAdsAPI = async (adsData) => {
  try {
    const endpoint = `${API_BASE_URL}feature-ads`; 
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
    };

    const formData = new FormData();

    // Define key-value pairs to append
    const keyValuePairs = [
      { key: 'Title', value: adsData.Title },
      { key: 'TargetUrl', value: adsData.TargetUrl },
      { key: 'StartDate', value: adsData.StartDate },
      { key: 'EndDate', value: adsData.EndDate },
      { key: 'CreatedBy', value: adsData.CreatedBy },
      { key: 'AdsLayout', value: adsData.AdsLayout }
    ];

    // Generate and append key-value pairs using map
    keyValuePairs.map(pair => {
      formData.append(pair.key, pair.value);
      return pair; 
    });

    adsData.AdsplacementIndex.forEach((index, i) => {
      const placement = adsData.AdsPagePlacements[index];
      if (placement) {
        formData.append(`AdsPagePlacements[${i}].AdsPageId`, placement.AdsPageId);
      }
    });
    // Generate and append files using map
    adsData.Files.map(file => {
      formData.append('Files', file);
      return file; 
    });

    const res = await axios.post(endpoint, formData, { headers: headers });

    if (res.data.StatusCode === 200) {
      console.log("Ad created successfully");
      toast.success("Ad created successfully.");
    
    } else {
      toast.error(res.data.Message || "Failed to create the ad.");
    
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
};

const UpdateAdsApi = async(adsData) => {
  try {
    const endpoint = `${API_BASE_URL}feature-ads?id=${adsData.adsId}`; 
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
    };

    const formData = new FormData();

    // Define key-value pairs to append
    const keyValuePairs = [
      { key: 'Title', value: adsData.Title },
      { key: 'TargetUrl', value: adsData.TargetUrl },
      { key: 'StartDate', value: adsData.StartDate },
      { key: 'EndDate', value: adsData.EndDate },
      { key: 'UpdatedBy', value: adsData.UpdatedBy },
      { key: 'AdsLayout', value: adsData.AdsLayout }
    ];

    // Generate and append key-value pairs using map
    keyValuePairs.map(pair => {
      formData.append(pair.key, pair.value);
      return pair; 
    });

    adsData.AdsplacementIndex.forEach((index, i) => {
      const placement = adsData.AdsPagePlacements[index];
      if (placement) {
        formData.append(`AdsPagePlacements[${i}].AdsPageId`, placement.AdsPageId);
      }
    });
    // Generate and append files using map
    adsData.Files.map(file => {
      formData.append('Files', file);
      return file; 
    });

    const res = await axios.put(endpoint, formData, { headers: headers });

    if (res.data.StatusCode === 200) {
      console.log("Ad Update successfully");
      toast.success("Ad Update successfully.");
    
    } else {
      toast.error(res.data.Message || "Failed to create the ad.");
    
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
}



export { GetAdsAPI, DeleteAdsAPI, CreateAdsAPI, GetAdsPageAPI, UpdateAdsApi };
