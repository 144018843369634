import axios from "axios";
import { _DecryptService } from "../../service/EncryptDecryptService";

const GetInquiryAPI = async (payload, setData, toast, setTotalCount) => {
  try {
    const endpoint = process.env.REACT_APP_API_ENDPOINT + "feature-inquiry/filter";
    console.log("Endpoint", endpoint);
    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json"
    };
    
    const res = await axios.post(endpoint, payload, { headers: headers });
    if (res.data.StatusCode === 200) {
      console.log("Data retrieved successfully");
      setData(res?.data?.Data?.DataLst || []);
      const totalRecords = res?.data?.Data?.PageSetting?.TotalCount || 0;
      setTotalCount(totalRecords);
      console.log("Total Count:", totalRecords);
    } else if (res.data.StatusCode === 401) {
      alert(res.data.Message);
      window.location.replace('/admin/login');
    } else {
      toast.error(res.data.Message);
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
};

const UpdateInquiryAPI = async (inquiryId, updatedData, toast) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}feature-inquiry?id=${inquiryId}`;
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json"
    };

    const res = await axios.patch(endpoint, updatedData, { headers: headers });

    if (res.data.StatusCode === 200) {
      console.log("Update successful");
      toast.success("Inquiry updated successfully");
    } else if (res.data.StatusCode === 401) {
      alert(res.data.Message);
      window.location.replace('/admin/login');
    } else {
      toast.error(res.data.Message);
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
};

const DeleteInquiryAPI = async (inquiryId, toast,setData,setIsLoading,payload) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}feature-inquiry?id=${inquiryId}`;
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json"
    };

    const res = await axios.delete(endpoint, { headers: headers });

    if (res.data.StatusCode === 200) {
      console.log("Delete successful");
      toast.success("Inquiry deleted successfully");            
      setIsLoading(true);
      await GetInquiryAPI(payload, setData, toast);          
      setIsLoading(false);
    } else if (res.data.StatusCode === 401) {
      alert(res.data.Message);
      window.location.replace('/admin/login');
    } else {
      toast.error(res.data.Message);
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
};

const CreateInquaryAPI = async (payload) => {
  try {
    const endpoint = process.env.REACT_APP_API_ENDPOINT + "feature-inquiry";
    console.log("Endpoint", endpoint);

    const res = await axios.post(endpoint, payload);
    if (res.data.StatusCode === 200) {
      console.log("Inquary created successfully");
    }

  } catch (err) {
    console.error("Error", err);
  }
}

export { GetInquiryAPI, UpdateInquiryAPI, DeleteInquiryAPI,CreateInquaryAPI };
