import axios from 'axios';
import { _DecryptService, _EncryptService } from '../../service/EncryptDecryptService';



const LoginAPI = async (postBody, toast, history) => {
  const endpoint = process.env.REACT_APP_API_ENDPOINT + "feature-account/login";

  try {
    const res = await axios.post(endpoint, postBody);
    console.log("Response:", res);
    console.log("StatusCode:", res.data.StatusCode);

    if (res.data.StatusCode === 200) { // Compare status code as a number
      // const userName = res.data.Data.UserName;
      // const userRole = res.data.Data.UserRole;
      const token=res.data.Token;
      // const userId = res.data.Data.UserId;
      // const decodedToken= _DecryptService(token)
      // const userId = decodedToken?.UserId;
      // sessionStorage.setItem("UserId", userId)
      // sessionStorage.setItem("userName", _EncryptService(userName));
      // sessionStorage.setItem("email",_EncryptService(email));
      // sessionStorage.setItem("userRole", _EncryptService(userRole));
      sessionStorage.setItem("token",_EncryptService(token));
    

      // sessionStorage.setItem("userId", userId);
      
      toast.success("Login Successful");
      window.location.replace('/admin/manage')
    }
    else if(res.data.StatusCode==401) {      
      alert(res.data.Message);
      window.location.replace('/admin/login')
    }
    else {
      toast.error(res.data.Message);
    }
  } catch (err) {
    console.error("Error at Login:", err.response ? err.response.data : err.message);
    toast.error("Unknown Error");
  }
}

export default LoginAPI;
