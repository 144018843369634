import React from "react";
import { ThemeProvider } from "@emotion/react";
import theme from '../../../theme';
import { Box, Typography } from "@mui/material";
import MaintenancePage from "../../maintenance/MaintenancePage";
export default function DashboardPage({history}){
    return(
        <ThemeProvider theme={theme}> 
            <Box sx={{marginTop:{lg:-12}}}>
            <MaintenancePage/>
            </Box>
        </ThemeProvider>
    )
}