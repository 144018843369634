import axios from "axios";
import { LocationOn } from '@mui/icons-material';


const GetDivisionTownshipAPI=async(setDataForDivision)=>{
    await axios.get('https://nksoftware-001-site17.dtempurl.com/MM_StateDivisionDistrictsTownships.json')
    .then((res)=>{
        console.log("Response",res.data.data.mm);
        const Location = res.data?.data;
        console.log(Location)
        setDataForDivision(Location)
    })
}

const GetPropertyTypeAPI = async (setTypeForProperty) => {
    await axios.get('https://nksoftware-001-site17.dtempurl.com/PropertyTypes.json')
    .then((res) => {
        console.log("Response",res.data);
        const PropertyType = res.data;
        console.log(PropertyType)
        setTypeForProperty(PropertyType)
    })
}
export {GetDivisionTownshipAPI,GetPropertyTypeAPI};