import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import UserNavbarComponent from "../../components/Navbar/UserNavbarComponent";
import theme from "../../theme";
import { ThemeProvider } from "@emotion/react";
import FooterComponent from "../../components/Footer/FooterComponent";
import { Box, TablePagination, Fab, useMediaQuery, Slide, Backdrop, IconButton } from '@mui/material';
import UserProductDisplayCard from "../../components/Card/UserProductDisplayCard";
import UserPropertyFilterComponent from "../../components/Drawer/UserPropertyFilterComponent";
import { GetPropertyAPI } from "../../api/Listings/property/propertyController";
import UserProductSkeletonLoader from './../../components/Skeleton/UserProductSkeletonLoader';
import FilterListIcon from '@mui/icons-material/FilterList'; // Use MUI icon for FAB
import CloseIcon from '@mui/icons-material/Close'; // Import close icon for the filter panel
import { GetCarApi, GetCarByid } from "../../api/Listings/car/carController";
import UserCarFilterComponent from "../../components/Drawer/UserCarFilterComponent";
import FetchData from "../../components/Drawer/UserCarFilterComponent";


const CarPage = ({ history }) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);  // 0-based index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [State, setState] = useState('');
  const [AddiitonFilter, setAddiitonFilter] = useState({});
  const [IsLoading, setIsLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false); // State to control FAB menu
  const [FormFromHomePage, setFormFromHomePage] = useState({});
  const Type = 'car';
  let allTotalCount;
  const paramLocation = useLocation(); // Use useLocation hook
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [carId, setcarId] = useState()

  useEffect(() => {
    const queryParams = new URLSearchParams(paramLocation.search);
    const carIdParam  = queryParams.get('CarId');

    if (carIdParam ) {
      setcarId(carIdParam )
    }

  const Condition = queryParams.get('Condition') || '';
  const location = queryParams.get('location') || '';
  const Model = queryParams.get('Model') || '';
  const Manufacturer = queryParams.get('Manufacturer') || queryParams.get('Type') || '';
  const MinPrice = queryParams.get('MinPrice') || '';
  const MaxPrice = queryParams.get('MaxPrice') || '';

  // Update the state with the values from query parameters
  setFormFromHomePage({
    Condition: Condition,
    Model: Model,
    Location: location,
    Manufacturer: Manufacturer,
    MinPrice: MinPrice,
    MaxPrice: MaxPrice
  });

  }, [paramLocation.search]);

   // Second useEffect to fetch data whenever page, rowsPerPage, or filters change
  useEffect(() => {
    console.log("FormFromHomePage", FormFromHomePage);
    if ( !carId  ){
    fetchData(page + 1, rowsPerPage, AddiitonFilter);
    } else {
    if (carId) {
        GetCarByid(carId,setData)
        setIsLoading(false);
      }
    } // Correct spelling of `AdditionalFilter`
  }, [page, rowsPerPage, AddiitonFilter, FormFromHomePage,carId]);

  // Helper function to filter non-empty values
  const filterNonEmptyValues = (filter) => {
    return Object.fromEntries(
      Object.entries(filter).filter(([key, value]) => value !== null && value !== '')
    );
  };

  // Function to fetch data
  const fetchData = async (pageNo, pageSize, additionalFilter) => {
    setIsLoading(true);
    const payload = {
      pageNo,
      pageSize,
      ...filterNonEmptyValues(FormFromHomePage),
      ...filterNonEmptyValues(AddiitonFilter),
    };

    console.log('payload', payload);

    try {
      await GetCarApi(payload, setData, setTotalCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredValues = filterNonEmptyValues(AddiitonFilter);
  const isEmptyFilter = Object.keys(filteredValues).length === 0;

  if (isEmptyFilter) {
    allTotalCount = totalCount
  }

  console.log("Total count", totalCount);
  console.log("Data from car", data);
  console.log("State", State);


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleBackdropClick = () => {
    setOpenFilter(false);
  };
  return (
    <>

      <ThemeProvider theme={theme}>
    
          <UserNavbarComponent history={history} setState={setState} />

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              height: 'auto',
              position: 'relative',
              backgroundColor: theme.homePage.backgroundColor // Ensure the sliding panel is positioned relative to this container
            }}
          >
            {/* Left part (100% on small screens, 70% on large screens) */}
            <Box
              sx={{
                flex: isSmallScreen ? 1 : 7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: 'auto',
                width: '100%',
                padding: isSmallScreen ? 0 : 0, // Add padding for small screens
              }}
            >
              {
                IsLoading ?
                  <UserProductSkeletonLoader /> :
                  <UserProductDisplayCard
                    Data={data}
                    Type={Type}
                    history={history}
                  />
              }

              <TablePagination
                component="div"
                height={'50px'}
                count={totalCount}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                sx={{
                  height: 'auto', // Let it adjust to the content height
                  overflow: 'hidden', // Prevent overflow issues
                }}
              />
            </Box>

            {/* Filter Panel (always visible on larger screens, toggle on small screens) */}
            {!isSmallScreen && (
              <Box
                sx={{
                  flex: 3, // 30% width
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  height: 'auto'
                }}
              >
                <UserCarFilterComponent
                  totalCount={allTotalCount}
                  setAddiitonFilter={setAddiitonFilter}
                />
              </Box>
            )}

            {/* Floating Action Button for Filter on small screens */}
            {isSmallScreen && (
              <Fab
                color="primary"
                aria-label="filter"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={toggleFilter}
              >
                <FilterListIcon />
              </Fab>
            )}

            {/* Sliding Panel for Filter on small screens */}
            <Slide direction="left" in={openFilter} mountOnEnter unmountOnExit>
              <Box
                sx={{
                  flex: 'none',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  height: '100%',
                  width: 300, // Fixed width for small screens
                  position: 'fixed',
                  right: 0,
                  top: 0,
                  backgroundColor: 'white',
                  boxShadow: 3,
                  padding: 2,
                  zIndex: 1200, // Ensure it is above other content
                  overflowY: 'auto',
                }}
              >
                <IconButton
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                  onClick={toggleFilter}
                >
                  <CloseIcon />
                </IconButton>
                <UserCarFilterComponent
                  totalCount={allTotalCount}
                  setAddiitonFilter={setAddiitonFilter} />
              </Box>
            </Slide>

            {/* Backdrop for closing filter panel */}
            {isSmallScreen && openFilter && (
              <Backdrop
                open={openFilter}
                onClick={handleBackdropClick}
                sx={{ zIndex: 1100 }} // Ensure it is below the sliding panel
              />
            )}
          </Box>

          <FooterComponent />
      </ThemeProvider>
    </>
    
  )
}

export default CarPage
