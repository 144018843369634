import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useState, useEffect } from 'react';
import sharmalLogo from "../../assets/images/SharmalLogo.png";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { GetDivisionTownshipAPI, GetPropertyTypeAPI } from '../../api/constant/GetConstantDataController';
import { GetAllNeededDataForProperty } from '../../api/Listings/property/propertyController';
import { GetAllNeededDataFromCarApi } from '../../api/Listings/car/carController';
import theme from "../../theme";


// Styled components
const CustomTextField = styled(TextField)(({ theme }) => ({
  width: '250px',
  height: '60px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  borderRight: '1px solid #e0def7',
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  width: '250px',
  height: '60px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',  // Remove the border
    },
    '&:hover fieldset': {
      borderColor: 'transparent',  // Ensure border is removed on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',  // Ensure border is removed when focused
    },
    '& .MuiSelect-select': {
      padding: '16.5px 14px',  // Adjust padding as needed
    },
    borderRight: '1px solid #e0def7'
  }
}));
const UserFilterCardComponent = ({ history, TotalRecordForProperty, TotalRecoedForCar }) => {
  const [selected, setSelected] = useState('Property');
  const [age, setAge] = React.useState('');
  const [DataForDivision, setDataForDivision] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [City, setCity] = useState('');
  const [Type, setType] = useState('');
  const [location, setLocation] = useState('');
  const [TypeForProperty, setTypeForProperty] = useState([])
  const [Status, setStatus] = useState('')
  const [MinPrice, setMinPrice] = useState('')
  const [MaxPrice, setMaxPrice] = useState('')
  const [Condition, setCondition] = useState('')
  const [AvaliableManufactuer, setAvaliableManufactuer] = useState([])
  const [AvaliableYear, setAvaliableYear] = useState([])
  const [AvaliableColor, setAvaliableColor] = useState([])
  const [AvaliablePlateDivision, setAvaliablePlateDivision] = useState([])
  const [AvaliablePlateColor, setAvaliablePlateColor] = useState([])
  const [AvaliableCarModel, setAvaliableCarModel] = useState([])
  const [Manufacturer, setManufacturer] = useState('')
  const [Model, setModel] = useState('')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearchClick = () => {

    if (Status === 'ရောင်းရန်') {
      let url = '/property?State=ရောင်းရန်';

      if (City !== '') {
        url += `&City=${City}`;
      }
      if (location !== '') {
        url += `&location=${location}`;
      }
      if (Type !== '') {
        url += `&Type=${Type}`;
      }
      if (MinPrice !== '') {
        url += `&MinPrice=${MinPrice}`;
      }
      if (MaxPrice !== '') {
        url += `&MaxPrice=${MaxPrice}`;
      }

      history.push(url);
    }
    else if (Status === 'ငှားရန်') {
      let url = '/property?State=ငှားရန်';

      if (City !== '') {
        url += `&City=${City}`;
      }
      if (location !== '') {
        url += `&location=${location}`;
      }
      if (Type !== '') {
        url += `&Type=${Type}`;
      }
      if (MinPrice !== '') {
        url += `&MinPrice=${MinPrice}`;
      }
      if (MaxPrice !== '') {
        url += `&MaxPrice=${MaxPrice}`;
      }

      history.push(url);
    } else if (selected === 'Car') {
      let url = '/car?';

      if (Condition !== '') {
        url += `&Condition=${Condition}`;
      }
      if (Model !== '') {
        url += `&Model=${Model}`;
      }
      if (location !== '') {
        url += `&location=${location}`;
      }
      if (Manufacturer !== '') {
        url += `&Manufacturer=${Manufacturer}`;
      }
      if (MinPrice !== '') {
        url += `&MinPrice=${MinPrice}`;
      }
      if (MaxPrice !== '') {
        url += `&MaxPrice=${MaxPrice}`;
      }

      history.push(url);
    } else {
      // Handle other cases or provide a default route if necessary
      console.warn('No valid status selected');
    }

    console.log(Status)
    console.log(City)
    console.log(location)
    console.log(MinPrice)
    console.log(MaxPrice)
    console.log(Type)
  };

  console.log("TotalRecordForProperty", TotalRecordForProperty)

  useEffect(() => {
    const fetchStateDivision = async () => {
      try {
        await GetDivisionTownshipAPI(setDataForDivision)
      } catch (error) {
        console.error("Error fetching StateDivision:", error);
      }
    }

    const fetchTypeForProperty = async () => {
      try {
        await GetPropertyTypeAPI(setTypeForProperty)
      } catch (error) {
        console.error("Error fetching type for property", error)
      }
    }

    const fetchDataForCar = async () => {
      try {
        await GetAllNeededDataFromCarApi(TotalRecoedForCar, setAvaliableManufactuer, setAvaliableYear, setAvaliableColor, setAvaliablePlateDivision, setAvaliablePlateColor, setAvaliableCarModel)
      } catch (error) {
        console.error("Error fetching AvaliableManufactuer :", AvaliableManufactuer);
      }
    }

    fetchDataForCar();
    fetchStateDivision();
    fetchTypeForProperty();
  }, [TotalRecordForProperty])

  const handleSelectChange = (value) => {
    setSelected(value)
  }


  const handleChange = (name, value) => {
    if (name === 'location') {
      const index = DataForDivision.findIndex(item => item.mm === value);
      setLocation(value);
      console.log('index', index)
      setSelectedIndex(index);
      setCity(''); // Reset city when location changes
    } else if (name === 'City') {
      setCity(value);
    }

    if (name === 'Condition') {
      setCondition(value)
    }

    if (name === 'Manufacturer') {
      setManufacturer(value)
    }

    if (name === 'Model') {
      setModel(value)
    }

    if (name === 'Type') {
      setType(value)
    }

    if (name === 'Status') {
      setStatus(value)
      console.log(Status)
    }

    if (name === 'MaxPrice') {
      setMaxPrice(value)
    }

    if (name === 'MinPrice') {
      setMinPrice(value)
    }

  }

  console.log('TypeForProperty', TypeForProperty)

  return (
    <Box
      className="Glassmorphism-card"
      sx={{
        maxWidth: 'md',
        height: 'auto',
        position: 'relative',
        top: '-159px',
        marginX: 'auto',
        padding: isMobile? '10px' : '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          flexWrap: 'wrap', // Ensures text wraps in smaller screens
          gap: '10px',      // Adds spacing between the Typography elements
        }}
      >
        <img
          src={sharmalLogo}
          alt="logo"
          style={{ width: '16%', height: '15%', marginRight: '10px' }}
        />

        <Typography
          variant="p"
          sx={{
            fontSize: {
              xs: '14px', // Mobile size
              sm: '23px', // Tablet size
              md: '23px', // Default size for larger screens
              xl: '23px'
            },
          }}
          className="gradient-text"
        >
          နှင့်သင့်စိတ်ကူးထဲက
        </Typography>

        <Typography
          variant="p"
          sx={{
            fontSize: {
              xs: '14px', // Mobile size
              sm: '23px', // Tablet size
              md: '23px', // Default size for larger screens

            },
          }}
          className="gradient-text-more"
        >
          အိမ်ခြံမြေနှင့်ကားများကို
        </Typography>

        <Typography
          variant="p"
          sx={{
            fontSize: {
              xs: '14px', // Mobile size
              sm: '23px', // Tablet size
              md: '23px', // Default size for larger screens
            },
          }}
          className="gradient-text"
        >
          လွယ်ကူလျင်မြန်စွာရှာဖွေလိုက်ပါ
        </Typography>
      </Box>



      <Box sx={{
        marginTop: '20px'
      }}>
        <Stack spacing={0} direction="row">
          <Button
            variant="contained"
            onClick={() => handleSelectChange('Property')}
            sx={{
              backgroundColor: selected === 'Property' ? '#ffffff' : '#f5f5f5',
              color: selected === 'Property' ? '#000000' : '#000000',
              borderBottom: selected === 'Property' ? '4px solid #ea625e' : 'none',
              fontWeight: 'bold',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            အိမ်ခြံမြေ
          </Button>

          <Button
            variant="contained"
            onClick={() => handleSelectChange('Car')}
            sx={{
              backgroundColor: selected === 'Car' ? '#ffffff' : '#f5f5f5',
              color: selected === 'Car' ? '#000000' : '#000000',
              borderBottom: selected === 'Car' ? '4px solid #ea625e' : 'none',
              fontWeight: 'bold',
              borderTopLeftRadius: 0,
              borderTopRightRadius: '8px',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            ကား
          </Button>
        </Stack>

        {selected === 'Property' ?
          <Box sx={{
            width: '100%',
            height: 'auto',
            backgroundColor: '#ffffff',
            borderTopLeftRadius: 0,
            borderTopRightRadius: '10px',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            boxShadow: '37px 18px 62px -51px rgba(0,0,0,0.58);'
          }}>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: '20px',
              borderBottom: '1px solid #e0def7',
              gap:isMobile? '0' : "40px"
            }}>
              {/* <CustomTextField
                label="စာသား (သို့) ကြော်ငြာနံပါတ်"
                maxRows={4}
                maxWidth={8}
              /> */}

              <CustomFormControl fullWidth>
                <InputLabel sx={{ height: '100px', fontSize: isMobile ? '10px' : '16px' }}>
                  ရောင်းရန်/ငှားရန်
                </InputLabel>
                <Select
                  value={Status}
                  onChange={(e) => handleChange('Status', e.target.value)}
                >
                  <MenuItem value="ရောင်းရန်">ရောင်းရန်</MenuItem>
                  <MenuItem value="ငှားရန်">ငှားရန်</MenuItem>
                </Select>
              </CustomFormControl>

              <CustomFormControl fullWidth>
                <InputLabel sx={{ height: '100px', fontSize: isMobile ? '10px' : '16px' }}>
                  တိုင်းဒေသကြီး
                </InputLabel>
                <Select
                  value={location}
                  label="တိုင်းဒေသကြီး"
                  onChange={(e) => handleChange('location', e.target.value)}
                >
                  {DataForDivision.map((Division, index) => (
                    <MenuItem key={index} value={Division.mm}>{Division.mm}</MenuItem>
                  ))}
                </Select>
              </CustomFormControl>

              <CustomFormControl fullWidth>
                <InputLabel sx={{ height: '100px', fontSize: isMobile ? '10px' : '16px' }}>
                  မြို့နယ်
                </InputLabel>
                <Select
                  value={City}
                  label="မြို့နယ်"
                  onChange={(e) => handleChange('City', e.target.value)}
                >
                  {selectedIndex !== null && DataForDivision[selectedIndex]?.districts?.length > 0
                    ? DataForDivision[selectedIndex].districts.flatMap((district) =>
                      district.townships.map((township, index) => (
                        <MenuItem key={index} value={township.mm}>
                          {township.mm}
                        </MenuItem>
                      ))
                    )
                    : <MenuItem value="" disabled>No townships available</MenuItem>}
                </Select>
              </CustomFormControl>


            </Box>



            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: isMobile? '20px 3px 20px 0' :'20px',
            }}>
              <CustomFormControl fullWidth>
                <InputLabel sx={{ height: '100px', fontSize: isMobile ? '10px' : '16px' }}>အိမ်ခြံမြေအမျိုးအစား </InputLabel>
                <Select
                  value={Type}
                  label="type"
                  onChange={(e) => handleChange('Type', e.target.value)}

                >
                  {TypeForProperty.PropertyTypes && TypeForProperty.PropertyTypes.map(type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </CustomFormControl>

              <CustomFormControl fullWidth>
                <TextField
                  value={MinPrice}
                  label="အနည်းဆုံးဈေးနှုန်း"
                  type="number" // Makes it a numeric input
                  onChange={(e) => handleChange('MinPrice', e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      height: '300px',
                  fontSize: isMobile ? '10px' : '16px'  // Set the height properly as a string
                    },
                  }}
                />
              </CustomFormControl>


              <CustomFormControl fullWidth>

                <TextField
                  value={MaxPrice}
                  label="အများဆုံးဈေးနှုန်း"
                  type="number" // Makes it a numeric input
                  onChange={(e) => handleChange('MaxPrice', e.target.value)}
                  variant="outlined"
                  inputProps={{ min: 0 }}
                  InputLabelProps={{
                    style: {
                      height: '300px',
                      fontSize: isMobile ? '10px' : '16px' // Set the height properly as a string
                    },
                  }}
                />
              </CustomFormControl>

              <Button variant="contained" sx={{
                width: isMobile? '90px' : '250px',
                height: '60px',
                backgroundColor: '#EA625E',
                fontSize: isMobile? '10px' :'auto'
              }}
                onClick={handleSearchClick}
              >
                ရှာဖွေရန်
              </Button>
            </Box>


          </Box> :

          <Box sx={{
            width: '100%',
            height: 'auto',
            backgroundColor: '#ffffff',
            borderTopLeftRadius: 0,
            borderTopRightRadius: '10px',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            boxShadow: '37px 18px 62px -51px rgba(0,0,0,0.58);'
          }}>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: '20px',
              borderBottom: '1px solid #e0def7',
              gap:isMobile? '0' : "40px"
            }}>
              {/* <CustomTextField
                label="စာသား (သို့) ကြော်ငြာနံပါတ်"
                maxRows={4}
                maxWidth={8}
              /> */}

              <CustomFormControl fullWidth>
                <InputLabel sx={{ height: '100px', fontSize: isMobile ? '10px' : '16px' }}>Condition အားလုံး</InputLabel>
                <Select
                  value={Condition}
                  label="Condition အားလုံး"
                  onChange={(e) => handleChange('Condition', e.target.value)}

                >
                  <MenuItem value="Brand New">Brand New</MenuItem>
                  <MenuItem value="Used">Used</MenuItem>
                </Select>
              </CustomFormControl>

              <CustomFormControl fullWidth>
                <InputLabel sx={{ height: '100px', fontSize: isMobile ? '10px' : '16px' }}>ကားထုတ်လုပ်သူ</InputLabel>
                <Select
                  value={Manufacturer}
                  label="ကားထုတ်လုပ်သူ"
                  onChange={(e) => handleChange('Manufacturer', e.target.value)}

                >
                  {AvaliableManufactuer.map((manufacturer, index) => (
                    <MenuItem key={index} value={manufacturer}>
                      {manufacturer}
                    </MenuItem>
                  ))}
                </Select>
              </CustomFormControl>

              <CustomFormControl fullWidth>
                <InputLabel sx={{ height: '100px', fontSize: isMobile ? '10px' : '16px' }}>ကားမော်ဒယ််</InputLabel>
                <Select
                  value={Model}
                  label="ကားမော်ဒယ််"
                  onChange={(e) => handleChange('Model', e.target.value)}
                >
                  {AvaliableCarModel.map((CarModel, index) => (
                    <MenuItem key={index} value={CarModel}>
                      {CarModel}
                    </MenuItem>
                  ))}
                </Select>
              </CustomFormControl>

            </Box>



            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: isMobile? '20px 3px 20px 0' :'20px',

            }}>
              <CustomFormControl fullWidth>
                <InputLabel sx={{ height: '100px', fontSize: isMobile ? '10px' : '16px' }}>တိုင်းဒေသကြီး</InputLabel>
                <Select
                  value={location}
                  label="တိုင်းဒေသကြီး"
                  onChange={(e) => handleChange('location', e.target.value)}

                >
                  {DataForDivision.map((Division, index) => (
                    <MenuItem key={index} value={Division.mm}>{Division.mm}</MenuItem>
                  ))}
                </Select>
              </CustomFormControl>

              <CustomFormControl fullWidth>
                <TextField
                  value={MinPrice}
                  label="အနည်းဆုံးဈေးနှုန်း"
                  type="number" // Makes it a numeric input
                  onChange={(e) => handleChange('MinPrice', e.target.value)}
                  variant="outlined"
                  inputProps={{ min: 0 }}
                  InputLabelProps={{
                    style: {
                      height: '300px', // Set the height properly as a string
                      fontSize: isMobile ? '10px' : '16px'
                    },
                  }}
                />
              </CustomFormControl>


              <CustomFormControl fullWidth>

                <TextField
                  value={MaxPrice}
                  label="အများဆုံးဈေးနှုန်း"
                  type="number" // Makes it a numeric input
                  onChange={(e) => handleChange('MaxPrice', e.target.value)}
                  variant="outlined"
                  inputProps={{ min: 0 }}
                  InputLabelProps={{
                    style: {
                      height: '300px', // Set the height properly as a string
                      fontSize: isMobile ? '10px' : '16px'
                    },
                  }}
                />
              </CustomFormControl>

              <Button variant="contained" sx={{
                width: isMobile? '90px' : '250px',
                height: '60px',
                backgroundColor: '#EA625E',
                fontSize: isMobile? '10px' :'auto'
              }}
                onClick={handleSearchClick}
              >
                ရှာဖွေရန်
              </Button>
            </Box>


          </Box>
        }

      </Box>


    </Box>
  );
};

export default UserFilterCardComponent;
