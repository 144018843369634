// SelectableDataForProperty.js

export const SelectableDataForProperty = {
  locationRegions: [
    'ရန်ကုန်တိုင်းဒေသကြီး',
    'မန္တလတိုင်းဒေသကြီး',
    'ကချင်ပြည်နယ်',
    'ရှမ်းပြည်နယ်',
    'နေပြည်တော်'
  ],
  cities: [
    'ရန်ကင်း',
    'တောင်ဥက္ကလာပ',
    'တာမွေ'
  ],
  Possiblestatues: [
    'ရောင်းရန်',
    'ငှားရန်'
  ],
  bedrooms: [
    '1',
    '2',
    '3',
    '4'
  ],
  bathrooms: [
    '1',
    '2',
    '3',
    '4'
  ],
  types: [
    'လုံးချင်းအိမ်',
    'တိုက်ခန်း',
    'မြေကွက်',
    'ဆိုင်ခန်း',
    'ရုံးခန်း'
  ],
  conditions: [
    'ဆောက်လုပ်ပြီး',
    'ဆောက်လုပ်ဆဲ'
  ],
  floors: [
    'Whole property',
    'Ground floor',
    '1st floor',
    '2nd floor',
    '3rd floor',
    '4th floor',
    '5th floor',
    '6th floor',
    '7th floor',
    '8th floor',
    '9th floor',
    '10th floor',
    '11th floor',
    '12th floor',
    '13th floor',
    '14th floor',
    '15th floor',
    '16th floor',
    '17th floor',
    '18th floor',
    '19th floor',
    '20th floor',
  ],
  carColors: [
    'White',
    'Black',
    'Silver',
    'Blue',
    'Red'
  ]
};

// SelectableDataForCar.js

export const SelectableDataForCar = {
  Divisions: [
    'YGN',
    'MDY',
    'BGO',
    'MGY',
    'NPT'
  ],
  PlateColors: [
    'Black',
    'Red',
    'Blue',
    'Yellow'
  ],
  FuelTypes: [
    'Petrol',
    'Diesel',
    'Electric'
  ],
  LicenseStatuses: [
    'Valid',
    'Expired',
    'Pending Renewal',
    'Suspended'
  ],
  locations: [
    'ရန်ကုန်တိုင်းဒေသကြီး',
    'မန္တလတိုင်းဒေသကြီး',
    'ကချင်ပြည်နယ်',
    'ရှမ်းပြည်နယ်',
    'နေပြည်တော်'
  ],
  cities: [
    'ရန်ကင်း',
    'တောင်ဥက္ကလာပ',
    'တာမွေ'
  ],
  conditions: [
    'Brand New',
    'Used'
  ],
  manufacturers: [
    'Toyota',
    'Honda',
    'Suzuki',
    'Nissan',
    'Ford'
  ],
  buildTypes: [
    'Sedan',
    'SUV',
    'Pickup Truck',
    'Hatchback',
    'Minivan'
  ],
  carColors: [
    'White',
    'Black',
    'Silver',
    'Blue',
    'Red'
  ]
};



