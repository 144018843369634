import React from 'react';
import { Box, Typography } from '@mui/material';

const UserCategoryCard = ({Title,PropertyCatagoryData, Type,history}) => {

  const handleDetail =(label) => {
    console.log('label', label)
    Type === 'Property' ?
    history.push(`/property?State=All&Type=${label}`)
    :
    history.push(`/car?Type=${label}`)
    
  }
  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 0
      }}>
        <Typography 
          variant='p' 
          className='UserPageTitleStyle' 
          sx={{
            paddingLeft: { xs: '20px', md: '95px',xl: '10px' }, // Adjust padding for mobile
            marginBottom: '20px',
            textAlign: { xs: 'center', md: 'left' } // Center title on mobile
          }}
        >
          {Title}
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: {  
            xs: '20px',
            md: Type === 'Property' ? '100px' : '50px',
          },
          justifyContent: { xs: 'flex-start', md: 'flex-start' }, // Left-align on mobile
          overflowX: 'auto', // Allow horizontal scrolling
          paddingLeft: { xs: '20px', md: '95px',xl: '10px'  }, // Add padding on mobile
          scrollSnapType: 'x mandatory' // Enable snap scrolling
        }}>
          {PropertyCatagoryData.map((icon, index) => (
            <Box
              key={index}
              onClick={() => handleDetail(icon.label)}
              sx={{
                backgroundColor: '#FFFFFF',
                width: '140px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '20px',
                padding: '10px 0',
                marginBottom: '20px',
                flexShrink: 0, // Prevent shrinking for scrollable items
                scrollSnapAlign: 'start', // Align items when scrolling
                '&:hover': {
                  transform: 'scale(1.01)', // Scale the Box on hover to create a pop effect
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Add a shadow to enhance the effect
                }
              }}
            >
              <img
                src={`${icon.src}`}
                alt={icon.label}
                style={ Type == 'Car' ? { width: '100px', height: '80px', marginBottom: '10px',  } :
                { width: '80px', height: '80px', marginBottom: '10px',  }
              }
              />
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {icon.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default UserCategoryCard;
