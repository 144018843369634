import WholeIcon from '../assets/icons/wholeIcon.png';
import ApartmentIcon from '../assets/icons/ApartmentIcon.png';
import CondoIcon from '../assets/icons/ConDoIcon.png';
import LandIcon from '../assets/icons/landIcon.png';
import ShopIcon from '../assets/icons/ShopIcon.png';
import OfficeIcon from '../assets/icons/OfficeIcon.png';

import Yangon from '../assets/images/Yangon.png';
import Mandalay from '../assets/images/Mandalay.png';
import Naypitaw from '../assets/images/Naypitaw.png';
import Paekuu from '../assets/images/Paekuu.png';
import Ayeyawaddy from '../assets/images/Ayeyawaddy.png'

import ToyotaIcon from '../assets/icons/ToyotaIcon.png'
import LexusIcon from '../assets/icons/LexusIcon.png'
import HyndaiIcon from '../assets/icons/HyndaiIcon.png'
import MercedesIcon from '../assets/icons/MercedesIcon.png'
import HondaIcon from '../assets/icons/HondaIcon.png'
import NissaIcon from '../assets/icons/NissaIcon.png'
import FordIcon from '../assets/icons/FordIcon.png'


const PropertyCatagory = [
  {
    "src": WholeIcon,
    "label": "လုံးချင်းအိမ်"
  },
  {
    "src": ApartmentIcon,
    "label": "တိုက်ခန်း"
  },
  {
    "src": CondoIcon,
    "label": "ကွန်ဒို"
  },
  {
    "src": OfficeIcon,
    "label": "ရုံးခန်း"
  },
  {
    "src": ShopIcon,
    "label": "ဆိုင်ခန်း"
  },
  {
    "src":  LandIcon,
    "label": "မြေကွက်"
  }
]

const PropertyByTownship = [
  {
    name: 'နေပြည်တော်',
    image: Naypitaw,
    data: 'နေပြည်တော်'
  },
  {
    name: 'ရန်ကုန်',
    image: Yangon,
    data: 'ရန်ကုန်တိုင်းဒေသကြီး'

  },
  {
    name: 'မန္တလေး',
    image: Mandalay,
    data: 'မန္တလေးတိုင်းဒေသကြီး'

  },
  {
    name: 'ဧရာဝတီ',
    image: Ayeyawaddy,
    data: 'ဧရာဝတီတိုင်းဒေသကြီး'

  },
  {
    name: 'ပဲခူး',
    image: Paekuu,
    data: 'ပဲခူးတိုင်းဒေသကြီး'

  }
]

const CarCatagory = [
  {
    "src" : ToyotaIcon,
    "label": "Toyota"
  },
  {
    "src" : LexusIcon,
    "label": "Lexus"
  },
  {
    "src" : HyndaiIcon,
    "label": "Hyndai"
  },
  {
    "src" : MercedesIcon,
    "label": "Mercedes"
  },
  {
    "src" : HondaIcon,
    "label": "Honda"
  },
  {
    "src" : NissaIcon,
    "label": "Nissa"
  },
  {
    "src" : FordIcon,
    "label": "Ford"
  }
]

export  {PropertyCatagory, PropertyByTownship, CarCatagory};
