import axios from "axios";
import { _DecryptService } from "../../service/EncryptDecryptService";

const GetUserAPI = async (pageNo, pageSize, setData, toast, setTotalCount, setIsLoading) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}admin-account?pageNo=${pageNo}&pageSize=${pageSize}`;
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json"
    };
    setIsLoading(true);
    const res = await axios.get(endpoint, { headers: headers });
    
    if (res.data.StatusCode === 200) {
      console.log("Data retrieved successfully");
      setData(res?.data?.Data?.DataLst || []);
      const totalRecords = res?.data?.Data?.PageSetting?.TotalCount || 0;
      setTotalCount(totalRecords);
      console.log("Total Count:", totalRecords);
      console.log("Fetch Data",res?.data?.Data?.DataLst)
    } else if (res.data.StatusCode === 401) {
      alert(res.data.Message);
      window.location.replace('/admin/login');
    } else {
      toast.error(res.data.Message);
    }
    setIsLoading(false);
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
    setIsLoading(false);
  }
};

const UpdateAccountProfileAPI = async (payload,UserId,toast,onClose) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}admin-account/update-profile?id=${UserId}`;
    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json",
    };

    const res = await axios.put(endpoint, payload, { headers: headers });
    if (res.data.StatusCode === 200) {
      console.log("User Update successfully");
      toast.success("User profile Update successfully!");
      onClose()
    } else {
      toast.error(res.data.Message);
    }
  } catch (err) {
      console.error('Failed to update account profile:', err);
      toast.error("Unknown Error");
  }
};



const UpdatePasswordAPI = async (payload, userId, toast, onClose) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}feature-account/update-password?id=${userId}`;
    const token = _DecryptService(sessionStorage.getItem("token"));
    
    const headers = {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const res = await axios.put(endpoint, payload, { headers });

    if (res.data.StatusCode === 200) {
      console.log("User password updated successfully");
      toast.success("User updated successfully!");
      onClose();
    } else {
      toast.error(res.data.Message || "Update failed.");
    }
  } catch (err) {
    console.error('Failed to update account password:', err);
    toast.error("An error occurred while updating the password.");
  }
};

export default UpdatePasswordAPI;





const CreateUserAPI = async (userData, toast, onClose) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}admin-account/register`;
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json",
    };

    const res = await axios.post(endpoint, userData, { headers: headers });

    if (res.data.StatusCode === 200) {
      console.log("User created successfully");
      toast.success("User created successfully!");
      onClose(); // Close the dialog
    } else if (res.data.StatusCode === 401) {
      alert(res.data.Message);
      window.location.replace('/admin/login');
    } else {
      toast.error(res.data.Message);
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
};

const DeleteUserAPI = async (userId, toast, setData, pageNo, pageSize, setTotalCount, setIsLoading) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}admin-account?id=${userId}`;
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json",
    };

    const res = await axios.delete(endpoint, { headers: headers });

    if (res.data.StatusCode === 200) {
      console.log("User deleted successfully");
      toast.success("User deleted successfully!"); 
      GetUserAPI(pageNo,pageSize,setData,toast,setTotalCount,setIsLoading);     
    } else if (res.data.StatusCode === 401) {
      alert(res.data.Message);
      window.location.replace('/admin/login');
    } else {
      toast.error(res.data.Message);
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
};

const ResetCountFail = async (userId,toast) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_ENDPOINT}admin-account?id=${userId}`;
    console.log("Endpoint", endpoint);

    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json",
    };

    const res = await axios.patch(endpoint,{}, { headers: headers });

    if (res.data.StatusCode === 200) {
      console.log("reset successful")
      toast.success("reset successful");
    } 
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
}

export { GetUserAPI, CreateUserAPI, DeleteUserAPI,UpdateAccountProfileAPI,UpdatePasswordAPI,ResetCountFail };
