import axios from "axios";
import { _DecryptService } from "../../service/EncryptDecryptService";

const GetExchangeRateAPI = async (setData, toast) => {
  try {
    const endpoint = process.env.REACT_APP_API_ENDPOINT + "feature-exchange-rate";
    console.log("Endpoint", endpoint);
    // const headers = {
    //   "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token"))
    // };
    const res = await axios.get(endpoint);
    if (res.data.StatusCode === 200) {
      setData(res.data.Data.DataLst);
      console.log("Success for exchange rate")
    } else if(res.data.StatusCode==401) {    
      console.log(res.data.Message)  
      alert(res.data.Message);
      window.location.replace('/admin/login')
    }
    else{
      console.log(res.data.Message)
      if (toast) toast.error(res.data.Message);
    }
  } catch (err) {
    console.error("Error", err);
    if (toast) toast.error("Unknown Error");
  }
};

const UpdateExchangeRateAPI = async (payload, toast) => {
  try {
    const endpoint = process.env.REACT_APP_API_ENDPOINT + "feature-exchange-rate";
    console.log("Endpoint", endpoint);
    const headers = {
      "Authorization": "Bearer " + _DecryptService(sessionStorage.getItem("token")),
      "Content-Type": "application/json"
    };
    const res = await axios.put(endpoint, payload, { headers: headers });
    if (res.data.StatusCode === 200) {
      toast.success("Exchange rates updated successfully");
    } else {
      toast.error(res.data.Message);
    }
  } catch (err) {
    console.error("Error", err);
    toast.error("Unknown Error");
  }
};

export { GetExchangeRateAPI, UpdateExchangeRateAPI };
